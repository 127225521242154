import { ProductProps } from './Summary.types';
import useSelector from '../../../../redux/typedHooks';

export const useProductData = ({ products }: ProductProps) => {
  const { mainProduct, addOnProducts } = products || {};

  const mainProductData = useSelector((state) =>
    mainProduct?.productId ? state.becomeAMember.data.products[mainProduct.productId] : undefined,
  );

  const mainProductPrice =
    (products && mainProductData?.productAppPrice[products.mainProduct.amount]?.itemTotalPrice) || 0;

  const availableAddonProductsDataMap = mainProductData?.availableAddOnProducts?.products?.reduce((acc, cur) => {
    acc[cur.productNumber] = cur;
    return acc;
  }, {});

  const addonProductsPrice = addOnProducts
    ? addOnProducts.reduce((acc, cur) => {
        const curPrice = availableAddonProductsDataMap?.[cur.productId]?.productAppPrice[cur.amount]?.itemTotalPrice;
        return acc + (curPrice || 0);
      }, 0)
    : 0;

  return { mainProductData, addOnProducts, mainProductPrice, availableAddonProductsDataMap, addonProductsPrice };
};
