import styled from 'styled-components';
import { Text } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { GridCol } from '@naf/grid';
import { nafColor } from '@nafcore/theme';

const StyledGridCol = styled(GridCol)`
  @media (min-width: ${breakpoints.m}) {
    grid-column: 9 / -1;
  }
`;

const SummaryWrapper = styled.div`
  background: ${nafColor.primary.dew};
  width: 100%;
`;

const Summary = styled.div`
  padding: ${spacing.space32};
`;

const SummaryHeading = styled(Text)`
  font-size: 1.375rem;
  margin-top: 0;
  margin-bottom: 0;
`;

const LineWrapper = styled.div<{ $withTopBorder?: boolean }>`
  display: flex;
  justify-content: space-between;
  border-top: ${({ $withTopBorder }) => ($withTopBorder ? '1px solid rgba(10, 10, 10, 0.12)' : undefined)};
  border-top: ${({ $withTopBorder }) => ($withTopBorder ? '1px solid rgba(10, 10, 10, 0.12)' : undefined)};
  margin-top: ${({ $withTopBorder }) => ($withTopBorder ? '32px' : undefined)};
  padding-top: ${spacing.space32};
`;

const LineText = styled(Text)`
  margin: 0;
  margin-bottom: 3px;
`;

const Price = styled(Text)`
  margin: 0;
  margin-bottom: 3px;
  min-width: 70px;
  text-align: right;
`;

const TotalPrice = styled(Text)`
  margin: 0;
  font-size: 1.375rem;
`;

export default { StyledGridCol, SummaryWrapper, Summary, SummaryHeading, LineWrapper, LineText, Price, TotalPrice };
