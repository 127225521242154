import ContentLoader from 'react-content-loader';
import { nafColor } from '@nafcore/theme';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@naf/theme';
import { GridCol } from '@naf/grid';

export const SummaryLoader = () => (
  <StyledGridCol>
    <ContentLoader
      uniqueKey="summary-loader"
      width="100%"
      viewBox="0 0 600 160"
      preserveAspectRatio="none"
      backgroundColor={nafColor.neutral.neutral1}
      foregroundColor={nafColor.neutral.neutral2}
    >
      <rect x="25" y="20" rx="0" ry="0" width="30%" height="20" />
      <rect x="25" y="60" rx="3" ry="3" width="60%" height="10" />
      <rect x="25" y="80" rx="3" ry="3" width="50%" height="10" />
      <rect x="0" y="110" rx="0" ry="0" width="100%" height="1" />
      <rect x="25" y="132" rx="4" ry="4" width="50%" height="10" />
    </ContentLoader>
  </StyledGridCol>
);

const StyledGridCol = styled(GridCol)`
  overflow: hidden;
  height: auto;
  border: 1px solid ${nafColor.neutral.neutral1};
  @media (min-width: ${breakpoints.m}) {
    grid-column: 9 / -1;
    min-height: 200px;
    svg {
      display: block;
      height: 200px;
      width: 100%;
    }
  }
`;
