import React, { Fragment } from 'react';
import { TextVariant } from '@naf/text';
import S from './Styles';
import { formattedPrice } from '../../../../lib/formattedPrice';
import { ProductProps } from './Summary.types';
import { useProductData } from './Summary.utils';
import { SummaryLoader } from '../../../../components/skeleton-loaders/SummaryLoader';
import useSelector from '../../../../redux/typedHooks';
import { ProductOrderType } from '../../forms/Membership';

export const Summary = ({ products }: ProductProps) => {
  const { mainProductData, addOnProducts, mainProductPrice, addonProductsPrice, availableAddonProductsDataMap } =
    useProductData({ products });

  const campaignCodeDetails = useSelector((state) => state.becomeAMember?.data?.campaignCodeDetails);

  return mainProductData ? (
    <S.StyledGridCol s="12" m="12" l="4" xl="4">
      <S.SummaryWrapper>
        <S.Summary>
          <S.SummaryHeading variant={TextVariant.Header3}>Oppsummering</S.SummaryHeading>
          {mainProductData && (
            <>
              <S.LineWrapper>
                <S.LineText variant={TextVariant.ArticleTextHeader}>{mainProductData.productName}</S.LineText>
                <S.Price variant={TextVariant.ArticleTextHeader}>{formattedPrice(mainProductPrice)} kr</S.Price>
              </S.LineWrapper>
              <S.LineText variant={TextVariant.ArticleText}>Antall: {products?.mainProduct.amount}</S.LineText>
            </>
          )}
          {addOnProducts?.map(({ amount, productId }) => {
            const product = availableAddonProductsDataMap && availableAddonProductsDataMap[productId];
            const price = product && product.productAppPrice && product.productAppPrice[amount];
            return product && !product.hideFromSummary ? (
              <Fragment key={productId}>
                <S.LineWrapper>
                  <S.LineText variant={TextVariant.ArticleTextHeader}>{product.productName}</S.LineText>
                  <S.Price variant={TextVariant.ArticleTextHeader}>
                    {price && price.itemTotalPrice > 0 ? `${formattedPrice(price.itemTotalPrice)} kr` : 'Gratis'}
                  </S.Price>
                </S.LineWrapper>
                <S.LineText variant={TextVariant.ArticleText}>Antall: {amount}</S.LineText>
              </Fragment>
            ) : null;
          })}
          {campaignCodeDetails?.campaignCode && campaignCodeDetails.isValid ? (
            <S.LineWrapper>
              <S.LineText variant={TextVariant.ArticleTextHeader}>
                Kampanje: {campaignCodeDetails?.campaignCode}
              </S.LineText>
            </S.LineWrapper>
          ) : null}
          <S.LineWrapper $withTopBorder>
            <S.LineText variant={TextVariant.ArticleTextHeader}>Totalt</S.LineText>
            <S.TotalPrice variant={TextVariant.ArticleTextHeader}>
              {formattedPrice(addonProductsPrice + mainProductPrice)} kr
            </S.TotalPrice>
          </S.LineWrapper>
        </S.Summary>
      </S.SummaryWrapper>
    </S.StyledGridCol>
  ) : (
    <SummaryLoader />
  );
};

export const SummaryProvider = ({ productOrder }: { productOrder: ProductOrderType }) => (
  <Summary products={{ mainProduct: productOrder }} />
); // only re-render at the component level, when firstName changes
